<template>
  <div class="SalesContract">
    <div class="box-card">
      <el-row style="margin-bottom: 16px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/home' }">Home</el-breadcrumb-item>
          <el-breadcrumb-item>Sales Contract</el-breadcrumb-item>
        </el-breadcrumb>
      </el-row>
      <el-row>
        <el-form ref="searchForm" :model="searchForm" size="small" :label-position="'left'">
          <div style="display: flex" class="vg_mb_8">
            <div class="vg_mr_8">
              <el-form-item label="PO#" :label-width="'40px'" prop="po">
                <el-input v-model="searchForm.po" clearable placeholder="Please enter"></el-input>
              </el-form-item>
            </div>
            <div class="vg_mr_8">
              <el-form-item label="Item#" :label-width="'50px'" prop="item">
                <el-input v-model="searchForm.item" clearable placeholder="Please enter"></el-input>
              </el-form-item>
            </div>
            <div class="vg_mr_8">
              <el-form-item label="Description" :label-width="'80px'" prop="description">
                <el-input v-model="searchForm.description" clearable placeholder="Please enter"></el-input>
              </el-form-item>
            </div>
            <div style="display: flex; align-items: baseline">
              <el-button size="mini" type="primary" @click="search()">Search</el-button>
              <el-button size="mini" type="info" @click="resetSearchForm()">Reset</el-button>
              <el-button size="mini" type="success" @click="exportExcel()">Export to Excel</el-button>
            </div>
          </div>
          <el-input v-model="localSearchInput" size="small" clearable placeholder="Keyword filter table current page data..."></el-input>
        </el-form>
      </el-row>
      <el-row v-loading="loading">
        <el-col :span="24">
          <el-table
            :cell-class-name="'cellClassName'"
            :height="currentInnerHeight"
            ref="table"
            :data="computedTableData"
            border
            class="mtrlsTable"
            :show-summary="true"
            :summary-method="getAllSummaries"
            style="width: 100%"
          >
            <el-table-column align="center" type="index" width="60" />
            <el-table-column align="left" label="PO#" prop="po" min-width="120" />
            <el-table-column align="left" label="Due Date" prop="dueDate" min-width="120" />
            <el-table-column align="left" label="Item#" prop="item" min-width="120" />
            <el-table-column align="left" label="Description" prop="description" min-width="120" />
            <el-table-column align="left" label="Quantity" prop="quantity" min-width="120" />
            <el-table-column align="left" label="Unit" prop="unit" min-width="120" />
            <el-table-column align="left" label="Port" prop="port" min-width="120" />
            <el-table-column align="left" label="Unit Price$" prop="unitPrice" min-width="120" />
            <el-table-column align="left" label="Amount$" prop="amount" min-width="120" />
            <el-table-column align="left" label="Class" prop="classCode" min-width="120" />
            <el-table-column align="left" label="Cohort" prop="cohort" min-width="120" />
            <el-table-column align="left" label="CATEGORY" prop="category" min-width="120" />
          </el-table>
        </el-col>
      </el-row>
      <el-row style="margin-top: 10px; text-align: right">
        <el-pagination
          @current-change="changePageSearch"
          @size-change="changeSizeSearch"
          :current-page="currentPage"
          :page-sizes="[100, 200, 500, 1000, 2000]"
          :page-size="searchForm.page_size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalItem"
        />
      </el-row>
    </div>
  </div>
</template>

<script>
import { BigNumber } from 'bignumber.js';
import { dyjAPI } from '@api/modules/dyj';
import { get } from '@api/request';
import { downloadFile } from '@assets/pubjs/common';

export default {
  name: 'SalesContract',
  components: {},
  data() {
    return {
      searchForm: {
        page_no: 1,
        page_size: 100,
        po: '',
        item: '',
        description: ''
      },
      tableData: [],
      totalItem: 0,
      currentPage: 1,
      loading: true,
      localSearchInput: '',
      currentInnerHeight: null
    };
  },
  computed: {
    computedTableData() {
      return this.tableData.filter(
        data => !this.localSearchInput || Object.values(data).some(value => String(value).toLowerCase().includes(this.localSearchInput.toLowerCase()))
      );
    }
  },
  created() {
    this.initData();
  },
  mounted() {
    let { y } = this.$refs.table.$el.getBoundingClientRect();
    this.currentInnerHeight = window.innerHeight - y - 60 + 'px';
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.table.doLayout();
    });
  },
  methods: {
    initData() {
      this.getData();
    },
    // 获取所有的物料信息
    getData() {
      this.loading = true;
      this.searchForm.page_no = this.currentPage;
      get(dyjAPI.getSalesContract, this.searchForm)
        .then(({ data }) => {
          if (data.code === 0) {
            this.tableData = data.data.list;
            this.totalItem = data.data.total;
          } else {
            this.$message.error(data.msg);
          }
          this.loading = false;
        })
        .catch(err => console.info('🚀 ~ file:SalesContract.vue method: line:108 -----', err));
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.getData();
    },
    changeSizeSearch(val) {
      this.searchForm.page_size = val;
      this.changePageSearch(1);
    },
    // 搜索按钮
    search() {
      this.currentPage = 1;
      this.changePageSearch(1);
    },
    // 重置按钮
    resetSearchForm() {
      this.$refs.searchForm.resetFields();
      this.changePageSearch(1);
    },
    exportExcel() {
      get(dyjAPI.exportExcelSalesContract, this.searchForm)
        .then(({ data }) => {
          if (data.code === 0) {
            downloadFile({ fileUrl: data.data.url, fileName: data.data.fileName });
          } else {
            this.$message.error(data.msg);
          }
          this.loading = false;
        })
        .catch(err => console.info('🚀 ~ file:SalesContract.vue method: line:165 -----', err));
    },
    getAllSummaries({ columns, data }) {
      return columns.map((column, index) => {
        if (index === 0) return 'Total';
        if ([1, 2, 3, 4, 5, 6, 7, 8, 10, 11, 12].includes(index)) return '';
        const values = data.map(item => Number(item[column.property]));
        const sum = values.reduce((x, item) => (item ? x.plus(item) : x), new BigNumber(0)).toNumber();
        return sum || '';
      });
    }
  }
};
</script>
<style scoped>
::v-deep.el-table th > .cell {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
::v-deep.el-table td > .cell {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>
<style>
.SalesContract .box-card {
  height: 100%;
  margin: 20px 40px 0 230px;
  padding-bottom: 10px;
}
.SalesContract .mtrlsTable {
  margin-top: 20px;
}
.cellClassName {
  padding: 0 !important;
}
</style>
